import React from 'react';

const CardOne = ({ className, children }) => (
  <div
    className={`bg-primary-pantoneclighter ${className}`}
    style={{
    //   boxShadow: '0 10px 28px rgba(0,0,0,.08)'
    }}
  >
    {children}
  </div>
);

export default CardOne;