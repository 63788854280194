import React from "react"
import Layout from "../components/layout/Layout"
import SEO from "../components/SEO"
import Button from "../components/Button"
import SplitSection from "../components/SplitSection"
import Img from 'gatsby-image';
import { graphql, useStaticQuery, Link } from 'gatsby'
import Portfolio from "../components/Portfolio"
import Posts from "../components/Posts"
import Testimonials from "../components/Testimonials"
import Suppliers from "../components/Suppliers"
import ContactForm from "../components/ContactForm"

const IndexPage = (props) => {

    const {about, sitesettings } = useStaticQuery(graphql`
        query {
            about: allFile(filter: {relativeDirectory: {eq: "page/about"} name: {eq: "index"}}) {
                edges {
                    node {
                        childMarkdownRemark {
                            html
                            excerpt(pruneLength: 250)
                                frontmatter {
                                    title
                                    seotitle
                                    seometadesc
                                    featureimagealt
                                    featureimage {
                                        childImageSharp {
                                            fluid(maxWidth: 4000, quality: 100) {
                                                ...GatsbyImageSharpFluid_withWebp 
                                            }
                                        }
                                    extension
                                    publicURL
                                }
                            }
                        }
                    }
                }
            }
            sitesettings: file(relativePath: {eq: "settings/settings-sitemeta.md"}) {
                id
                childMarkdownRemark {
                    frontmatter {
                        hometitle
                        title
                        description
                        author
                        ogdescription
                        ogtype
                    }
                }
            }
            contact: file(relativePath: {eq: "settings/settings-contact.md"}) {
                id
                childMarkdownRemark {
                    frontmatter {
                        contformtitle
                        contformdesc
                        lblcontformfname
                        lblcontformlname
                        lblcontformtel
                        lblcontformloc
                        lblcontformemail
                        lblcontformmessage
                        lblcontformbtn
                    }
                }
            }
        }
    `)
    const aboutdata = about.edges[0].node.childMarkdownRemark
    const settings = sitesettings.childMarkdownRemark
    return (
        <>
            <Layout location={props.location}>
                <SEO title={settings.frontmatter.hometitle} description={settings.frontmatter.description} image={aboutdata.frontmatter.featureimage.publicURL}/>
                <SplitSection id="about" primarySlot={
                    <div className="lg:pr-32 xl:pr-48">
                        <h1 className="text-3xl lg:text-5xl font-thin tracking-widest text-center lg:text-left">{aboutdata.frontmatter.title}</h1>
                        <p className="mt-8 text-xl font-thin text-center lg:text-left leading-relaxed">
                            {aboutdata.excerpt}
                        </p>
                        <div className="pt-8 text-right">
                            <Link to="/about/">
                                <Button>Continue reading...</Button>
                            </Link>
                        </div>
                    </div>
                }
                secondarySlot={
                    aboutdata.frontmatter.featureimage.extension === 'svg'?
                    <img style={{width: "100%", height: "100%", background: "#FFF"}} src={aboutdata.frontmatter.featureimage.publicURL} alt={aboutdata.frontmatter.featureimagealt} />
                    :
                    <Img style={{width: "100%", height: "100%", background: "#FFF"}} fluid={aboutdata.frontmatter.featureimage.childImageSharp.fluid} alt={aboutdata.frontmatter.featureimagealt} />
                }
                />
                <Portfolio />
                <div className="px-3">
                    <Suppliers />
                </div>
                <Posts />
                <Testimonials />
                <ContactForm />
            </Layout>
        </>
    )
}

export default IndexPage