import React from "react"
import LabelText from "./LabelText"
import Fade from "react-reveal/Fade"
import Card from "./CardOne"
import Img from "gatsby-image"
import Button from "./Button"
import { Link } from 'gatsby'
import portfolioData from "../data/portfolio-data"

const Portfolio = () => {

    const { portfolio, portfoliosettings } = portfolioData()
    const settings = portfoliosettings.childMarkdownRemark.frontmatter
    return (
        <>
            <section id="portfolio" className="py-20 overflow-hidden px-1">
                <div className="container mx-auto text-center"> 
                    <h2 className="text-3xl lg:text-5xl font-thin tracking-widest">{settings.title}</h2>
                    <LabelText className="mb-8 text-center">{settings.heading}</LabelText>
                    <div className="flex flex-col lg:flex-row lg:-mx-3 mt-12">
                    {portfolio.edges.map(edge => {
                        const { frontmatter, fields } = edge.node
                        return (
                            <Fade bottom key={fields.slug}>
                                <div className="flex-1 flex items-stretch px-3">
                                    <Card className="mb-8 relative transition duration-300 ease-in-out transform hover:scale-98 hover:shadow-lg hover:bg-opacity-75">
                                        <div><Link to={fields.slug} itemProp="url">
                                        { frontmatter.featureimage.extension === 'svg'?
                                        <img style={{height: "350px", background: "#FFF"}} src={frontmatter.featureimage.publicURL} alt={frontmatter.featureimagealt} />
                                        :
                                        <Img className="" style={{height: "350px", background: "#FFF"}} fluid={frontmatter.featureimage.childImageSharp.fluid} alt={frontmatter.featureimagealt} />
                                        }
                                        <div className="absolute top-5 w-full bg-primary bg-opacity-75">
                                            <p className="text-xl tracking-widest text-white font-hairline opacity-100 pt-2">{frontmatter.title}</p>
                                            <p className="text-sm tracking-widest text-white font-hairline uppercase opacity-100 pb-2 pt-0">{frontmatter.customerlocation}</p>
                                        </div>
                                        <p className="px-8 py-4 font-thin tracking-widest text-center">
                                            {edge.node.excerpt}
                                        </p>
                                        {/* <div className="pt-4 pb-8 text-center">
                                            <Link to={fields.slug} itemProp="url"><Button className="font-thin tracking-widest">Continue reading...</Button></Link>
                                        </div> */}
                                        </Link></div>
                                    </Card>
                                </div>
                            </Fade>
                            )
                        })}
                    </div>
                    <div className="pt-2 text-center">
                            <Link to="/portfolio/" itemProp="url"><Button className="uppercase font-thin tracking-widest">See all Projects</Button></Link>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Portfolio