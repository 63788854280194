import React from "react"
import LabelText from "./LabelText"
import Fade from "react-reveal/Fade"
import CustomerCard from "./CustomerCard"
import reviewData from "../data/review-data"

const Testimonials = () => {
    const { customers, customersettings } = reviewData()
    const settings = customersettings.childMarkdownRemark.frontmatter
    return (
        <>
            <section id="testimonials" className="py-20 overflow-hidden px-1">
                <Fade up> 
                <div className="container mx-auto">
                    <div className="text-center">
                        <h2 className="text-3xl lg:text-5xl font-thin tracking-widest">{settings.title}</h2>
                    </div>
                    <LabelText className="mb-8 text-center">{settings.heading}</LabelText>
                    <div className="flex flex-col lg:flex-row md:mx-3">
                        {customers.edges.map(edge => {
                            const { frontmatter } = edge.node
                            return (
                                <div key={frontmatter.customername} className="flex-1 px-3 flex items-stretch">
                                    <CustomerCard customer={edge.node} />
                                </div>
                            )
                        })}
                    </div>
                </div>
                </Fade>
            </section>
        </>
    )
}

export default Testimonials