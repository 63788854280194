import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image';

const Suppliers = () => {

    const {suppliersettings} = useStaticQuery(graphql`
    query {
        suppliersettings: file(relativePath: {eq: "settings/settings-suppliers.md"}) {
            id
            childMarkdownRemark {
              frontmatter {
                heading
                title
                suplogo1alt
                suplogo1href
                suplogo1 {
                    publicURL
                    extension
                    childImageSharp {
                        # Specify a fixed image and fragment.
                        # The default width is 400 pixels
                        fixed (height: 80) {
                          ...GatsbyImageSharpFixed
                          width
                          height
                        }
                    }
                }
                suplogo2alt
                suplogo2href
                suplogo2 {
                    publicURL
                    extension
                    childImageSharp {
                        # Specify a fixed image and fragment.
                        # The default width is 400 pixels
                        fixed (height: 80) {
                          ...GatsbyImageSharpFixed
                          width
                          height
                        }
                    }
                }
                suplogo3alt
                suplogo3href
                suplogo3 {
                    publicURL
                    extension
                    childImageSharp {
                        # Specify a fixed image and fragment.
                        # The default width is 400 pixels
                        fixed (height: 80) {
                          ...GatsbyImageSharpFixed
                          width
                          height
                        }
                    }
                }
                suplogo4alt
                suplogo4href
                suplogo4 {
                    publicURL
                    extension
                    childImageSharp {
                        # Specify a fixed image and fragment.
                        # The default width is 400 pixels
                        fixed (height: 80) {
                          ...GatsbyImageSharpFixed
                          width
                          height
                        }
                    }
                }
                suplogo5alt
                suplogo5href
                suplogo5 {
                    publicURL
                    extension
                    childImageSharp {
                        # Specify a fixed image and fragment.
                        # The default width is 400 pixels
                        fixed (height: 80) {
                          ...GatsbyImageSharpFixed
                          width
                          height
                        }
                    }
                }
              }
            }
          }
        }
`)

const settings = suppliersettings.childMarkdownRemark.frontmatter

    return (
        <>
            <section id="suppliers" className="py-20 overflow-hidden px-6">
                <div className="container mx-auto text-center">
                    <h2 className="text-3xl lg:text-5xl font-thin tracking-widest">{settings.title}</h2>
                    <p className="mb-8 text-center">{settings.heading}</p>
                    <div className="flex flex-row flex-wrap lg:-mx-3 mt-12 justify-center items-center">
                        <div className="fex-1 text-primary-pantoneb fill-current m-3 opacity-75 transition duration-300 ease-in-out transform hover:scale-95" style={{width: "280px"}}>
                            { settings.suplogo1 &&
                            <a href={settings.suplogo1href} target="_blank" rel="noreferrer" alt={settings.suplogo1alt}>
                                {settings.suplogo1.extension === 'svg'?
                                <img style={{maxHeight: "80px", maxWidth: "280px", height: "80px", width: "280px"}} src={settings.suplogo1.publicURL} alt={settings.suplogo1alt} />
                                :
                                <Img width={settings.suplogo1.childImageSharp.fixed.width} height={settings.suplogo1.childImageSharp.fixed.height} fixed={settings.suplogo1.childImageSharp.fixed} alt={settings.suplogo1alt} />
                                }
                            </a>
                            }
                        </div>
                        <div className="fex-1 text-primary-pantoneb fill-current m-3 opacity-75 transition duration-300 ease-in-out transform hover:scale-95" style={{width: "280px"}}>
                            { settings.suplogo2 && 
                            <a href={settings.suplogo2href} target="_blank" rel="noreferrer" alt={settings.suplogo2alt}>
                                {settings.suplogo2.extension === 'svg'?
                                <img style={{maxHeight: "80px", maxWidth: "280px", height: "80px", width: "280px"}} src={settings.suplogo2.publicURL} alt={settings.suplogo2alt} />
                                :
                                <Img width={settings.suplogo2.childImageSharp.fixed.width} height={settings.suplogo2.childImageSharp.fixed.height} fixed={settings.suplogo2.childImageSharp.fixed} alt={settings.suplogo2alt} />
                                }
                            </a>
                            }
                        </div>
                        <div className="fex-1 text-primary-pantoneb fill-current m-3 opacity-75 transition duration-300 ease-in-out transform hover:scale-95" style={{width: "280px"}}>
                            {settings.suplogo3 && 
                            <a href={settings.suplogo3href} target="_blank" rel="noreferrer" alt={settings.suplogo3alt}>
                                {settings.suplogo3.extension === 'svg'?
                                <img style={{maxHeight: "80px", maxWidth: "280px", height: "80px", width: "280px"}} src={settings.suplogo3.publicURL} alt={settings.suplogo3alt} />
                                :
                                <Img width={settings.suplogo3.childImageSharp.fixed.width} height={settings.suplogo3.childImageSharp.fixed.height} fixed={settings.suplogo3.childImageSharp.fixed} alt={settings.suplogo3alt} />
                                }
                            </a>
                            } 
                        </div>
                        <div className="fex-1 text-primary-pantoneb fill-current m-3 opacity-75 transition duration-300 ease-in-out transform hover:scale-95" style={{width: "280px"}}>
                            {settings.suplogo4 && 
                            <a href={settings.suplogo4href} target="_blank" rel="noreferrer" alt={settings.suplogo4alt}>
                                {settings.suplogo4.extension === 'svg'?
                                <img style={{maxHeight: "80px", maxWidth: "280px", height: "80px", width: "280px"}} src={settings.suplogo4.publicURL} alt={settings.suplogo4alt} />
                                :
                                <Img width={settings.suplogo4.childImageSharp.fixed.width} height={settings.suplogo4.childImageSharp.fixed.height} fixed={settings.suplogo4.childImageSharp.fixed} alt={settings.suplogo4alt} />
                                }
                            </a>
                            } 
                        </div>
                        <div className="fex-1 text-primary-pantoneb fill-current m-3 opacity-75 transition duration-300 ease-in-out transform hover:scale-95" style={{width: "280px"}}>
                            {settings.suplogo5 && 
                            <a href={settings.suplogo5href} target="_blank" rel="noreferrer" alt={settings.suplogo5alt}>
                                {settings.suplogo5.extension === 'svg'?
                                <img style={{maxHeight: "80px", maxWidth: "280px", height: "80px", width: "280px"}} src={settings.suplogo5.publicURL} alt={settings.suplogo5alt} />
                                :
                                <Img width={settings.suplogo3.childImageSharp.fixed.width} height={settings.suplogo3.childImageSharp.fixed.height} fixed={settings.suplogo5.childImageSharp.fixed} alt={settings.suplogo5alt} />
                                }
                            </a>
                            } 
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Suppliers